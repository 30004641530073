import './OpenTicketBar.css';

import { FC } from 'react';
import { Link } from 'react-router';
import { Icon } from 'src/components/primitives/Icon';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { AuthRequiredButton } from 'src/components/features/AuthRequiredButton';
import { Button } from 'src/components/styles/Button';
import { useCurrentStatus } from 'src/lib/currentStatus';

export const OpenTicketBar: FC<{ account: Pick<Account, 'gid' | 'screen_name' | 'business_profile'> }> = ({
  account,
}) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  return (
    <div className="open-ticket-bar">
      {account.business_profile?.accept_repair_request || account.gid == me?.gid ? (
        <AuthRequiredButton returnTo={`/${account.screen_name || `accounts/${account.gid}`}/ask`}>
          <Link
            to={`/${account.screen_name || `accounts/${account.gid}`}/ask`}
            className={Button.class}
            {...Button.build({ variant: 'nagaku-black' })}
          >
            <Icon name="send" />
            {account.gid == me?.gid ? i18n.t('action.check_my_ask_page') : i18n.t('action.ask')}
          </Link>
        </AuthRequiredButton>
      ) : (
        <button className={Button.class} {...Button.build({ variant: 'black' })} disabled>
          {i18n.t('action.pause_accepting')}
        </button>
      )}
    </div>
  );
};

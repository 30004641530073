import { factoryAccount } from 'src/catalog/factories/account';
import { factoryAccountSetting } from 'src/catalog/factories/accountSetting';
import { Meta, Story } from 'src/catalog/Story';
import { AskForm } from 'src/components/features/ticket/AskForm';
import { Account } from 'src/models/v1/account';
import { ComponentProps } from 'react';
import { CurrentStatusContext } from 'src/lib/currentStatus';

const meta: Meta<typeof AskForm, ComponentProps<typeof AskForm> & { me: Account }> = {
  title: 'Ask Form',
  args: {
    me: factoryAccount('caller'),
    account: factoryAccount('repairer'),
    setting: factoryAccountSetting('verified'),
  },
  template: ({ me, ...args }) => {
    return (
      <CurrentStatusContext.Provider
        value={{
          me,
          permissions: [],
        }}
      >
        <AskForm {...args} />
      </CurrentStatusContext.Provider>
    );
  },
};

export default meta;

export const emailNotVerified: Story<typeof AskForm, ComponentProps<typeof AskForm> & { me: Account }> = {
  name: 'When Email Not Verified',
  args: {
    me: factoryAccount('caller'),
    account: factoryAccount('repairer'),
    setting: factoryAccountSetting(),
  },
};

export const ownAskForm: Story<typeof AskForm, ComponentProps<typeof AskForm> & { me: Account }> = {
  name: 'When Own Ask Form',
  args: {
    me: { ...factoryAccount('repairer'), gid: 'REPAIRER' },
    account: { ...factoryAccount('repairer'), gid: 'REPAIRER' },
    setting: factoryAccountSetting('verified'),
  },
};

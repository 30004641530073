import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate, useLoaderData } from 'react-router';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { AskForm } from 'src/components/features/ticket/AskForm';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { Text } from 'src/components/primitives/Text';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { AccountSetting } from 'src/models/v1/account';
import { accountProfileLoader, Data } from 'src/pages/accounts/_gid/index';
import { AskFormHeader } from 'src/components/features/ticket/AskFormHeader';
import { ToDoAlert } from 'src/components/features/ToDoAlert';

export const AskPage: FC<{}> = () => {
  const { i18n } = useI18n();
  const { account, account_setting: setting } = useLoaderData() as Data & { account_setting: AccountSetting };
  const { me } = useCurrentStatus();

  /**
   * Auto-redirect to login page when not logged in.
   */
  if (!me) {
    const searchParams = new URLSearchParams();
    searchParams.set('returnTo', `/${account.screen_name || `accounts/${account.gid}`}/ask`);
    return <Navigate to={`/login?${searchParams.toString()}`} />;
  }

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.ask')} | ${account.display_name} | ${i18n.t('nagaku')}`}</title>
        <meta property="og:title" content={`${i18n.t('title.ask')} | ${account.display_name} | ${i18n.t('nagaku')}`} />
        <meta name="description" content={account.business_profile?.description || ''} />
        <meta property="og:description" content={account.business_profile?.description || ''} />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={`https://nagaku.com/${account.screen_name || `accounts/${account.gid}`}/ask`} />
      </Helmet>
      {account.gid == me.gid && (
        <>
          <ToDoAlert variant="primary" embbed>
            <ToDoAlert.Body>
              <ToDoAlert.Heading>{i18n.t('description.own_ask_page')}</ToDoAlert.Heading>
            </ToDoAlert.Body>
            <ToDoAlert.Button>
              <Link to={`/settings/reception/edit`}>
                <Text color="main" variant="label" size="medium" weight="bold">
                  {i18n.t('action.edit')}
                </Text>
              </Link>
            </ToDoAlert.Button>
          </ToDoAlert>
        </>
      )}
      <BackableBreadcrumb
        homePath={account.screen_name ? `/${account.screen_name}` : `/accounts/${account.gid}`}
        currentPath={account.screen_name ? `/${account.screen_name}/ask` : `/accounts/${account.gid}/ask`}
      >
        {i18n.t('title.ask')}
      </BackableBreadcrumb>
      <AskFormHeader account={account} />
      <AskForm account={account} setting={setting} />
    </NavigationLayout>
  );
};

export const askPageLoader: PageLoader = async (args) => {
  const { api } = args;
  const [data, { account_setting }] = await Promise.all([
    accountProfileLoader(args),
    api.fetch<{ account_setting: AccountSetting }>(`/v1/accounts/me/setting`),
  ]);
  return { ...data, account_setting };
};
